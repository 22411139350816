import React from "react"
import BodyLayout from "../components/bodyLayout"
import { Col, Row, Icon } from "antd"
import Img from "gatsby-image"
import SEO from '../components/SEO'

export default ({data}) => (
  <BodyLayout color='rgb(76, 64, 132)'>
  <SEO title={'Statiste - Dashboard'} description={"Conception de dashboards"} />
  <div className="notenoughcontent">
  <Row style={{paddingTop:30}}>
  <Col xs={{span:22,offset:1}} md={{span:20,offset:2}}>
    <Row type="flex" align="middle" left="space-between">
      <Col span={8}>
      <Img fluid={data.profile.childImageSharp.fluid} />
      </Col>
      <Col span={14} offset={1}>
      <h1 id="gir" style={{border: '3px dashed'}}>Des solutions web pour suivre votre business</h1>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Pour qui ?</h3>
      <p>
      <span id="dshb">Tous ceux qui veulent pouvoir suivre l'évolution de leur business</span> en quelques clics.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Comment ?</h3>
      <p>
      Du dashboard Excel à un site web dédié en passant par <a href="https://www.tableau.com/fr-fr" style={{color: 'rgb(76, 64, 132)'}}>Tableau</a>, chaque tableau de bord est unique !
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Pour quoi ?</h3>
      <p>
      <span id="dshb">Pour gagner du temps à trouver directement les KPIs importants</span>. Chaque vue donne des informations, de la vue la plus globale pour les réunions comex à la plus précise pour les opérations quotidiennes.
      </p>
      </Col>
    </Row>
  </Col>
  </Row>
  </div>
  </BodyLayout>
)

export const query = graphql`
  {
    profile: file(relativePath: {eq:"img/dashboards.jpg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1000){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `
